// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-projetos-js": () => import("./../src/templates/projetos.js" /* webpackChunkName: "component---src-templates-projetos-js" */),
  "component---src-templates-projeto-js": () => import("./../src/templates/projeto.js" /* webpackChunkName: "component---src-templates-projeto-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-js": () => import("./../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-sucesso-js": () => import("./../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */)
}

